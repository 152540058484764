import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Print from '@/utils/print.js'
Vue.use(Print) // 打印
Vue.config.productionTip = false
Vue.use(ElementUI)

//引入自定义指令，方便全局使用
import myDirective from '@/utils/myDirective.js';
Vue.use(myDirective);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
