import Vue from 'vue'
import Vuex from 'vuex'
import { login } from '@/request/login'
import { setToken, getToken } from '@/utils/auth'
import router from '@/router/index'
import { apply } from '@/store/module/apply'
import { beforeLoginApply } from '@/store/module/loginBeforeApply'
// import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo).xs_xm : '',
    userIdNum: sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo).xs_sfz : '',
    userPhone: sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo).mobile : '',
    userKaoc: sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo).kaoc_bm : '',
    token: getToken()
  },
  getters: {
  },
  mutations: {
    setUser (store, data) {
      store.userName = data.name
      store.userIdNum = data.xsSfz
      store.userPhone = data.mobile
      store.userKaoc = data.kaocBm
      router.push('/main')
    },
    loginState (state, res) {
      const data = res.v_list[0]
      res.v_list[0].token = res.token
      sessionStorage.userInfo = JSON.stringify(data)
      setToken(JSON.stringify(res.token))
      this.commit('setUser', {
        name: data.xs_xm,
        mobile: data.mobile,
        kaocBm: data.kaoc_bm,
        xsSfz: data.xs_sfz
      })
      // console.log(res, 'store')
      // router.push({
      //   path: '/selectSys'
      //   // path: '/home'
      // })
    },
    userExit (state) {
      state.userName = ''
      state.userId = ''
      state.userIdNum = ''
      state.userPhone = ''
      state.userKaoc = ''
      state.userZyId = ''
      state.userBranchId = ''
      sessionStorage.clear()
      router.push('/')
    }
  },
  actions: {
    stuLogin ({ commit }, data) {
      const param = {
        v_xs_sfz: data.user,
        v_login_pwd: data.pwd,
        v_login_type: 1,
        v_code: data.rmInput,
        rm: data.rmNum
      }
      return new Promise((resolve, reject) => {
        login(param).then(res => {
          if (res.success) {
            commit('loginState', res)
          }
          resolve(res)
          // this.$router.push('/main')
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  modules: {
    apply,
    beforeLoginApply
  }
})
