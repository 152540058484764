// 测试环境
//const webSite = 'http://120.27.150.141:97/xhuUnitMgt' 
//const mobileUrl = 'http://120.27.150.141:8013'

//正式地址
const webSite = 'https://zkbm.xhu.028zk01.net/xhuUnitMgt' 
const mobileUrl = 'https://zkbmh5.xhu.028zk01.net'
export default {
  webSite,
  mobileUrl
}
