<template>
  <div class="header-container">
    <div class="school-logo">
      <div class="logo"></div>
    </div>
    <div class="school-name">
      <h1>高等教育自学考试助学培训报名系统</h1>
    </div>
    <div v-if="$route.path !== '/' && !$route.meta.isApply" class="user-handle">
      当前用户: {{ userName }}
      <el-button class="exit" @click="quit">退出</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHead',
  data () {
    return {
      showUserName: false
    }
  },
  computed: {
    userName () {
      return this.$store.state.userName
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    quit () { // 退出到登录页
      this.$store.commit('userExit')
    }
  }
}
</script>

<style scoped lang="less">
.header-container{
  display: flex;
  padding: 0 20px;
  margin-bottom: 6px;
  box-sizing: border-box;
  background-color: #0356A1;
  .school-logo{
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .logo{
      height: 65px;
      width: 218px;
      background: url("../assets/login/logo.png") no-repeat 0 0;
      background-size: 100% 100%;
    }
  }
  .school-name{
    margin-left: 6vw;
    line-height: 100px;
    color: white;
    letter-spacing: 3px;
  }
  .user-handle{
    margin-left: auto;
    display: flex;
    align-items: center;
    color: white;
    .exit{
      margin-left: 30px;
    }
  }
}
@media screen and (max-width: 1366px){
  .school-logo{
    margin-left: 120px !important;
  }
  .school-name{
    margin-left: 4vw !important;
  }
  .user-handle{
    .exit{
      margin-left: 20px !important;
    }
  }
}
</style>
